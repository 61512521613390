.flash-error,
.flash-success{
    position: fixed;
    z-index: 2100;
    top: 40px; right: 40px;

    display: block;
    width: 300px;
    height: auto;
    padding: 20px 30px;

    background: lighten($error, 10%);

    p{
        color: white;

        &:first-of-type{
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 10px;
        }
    }
}

.flash-success{ background-color: $success; }
