$error: rgba(192, 57, 43, 1);
$success: rgba(46, 204, 113, 1);
$warning: rgba(241, 196, 15, .7);

$primary: rgb(29,172,251);
$subprimary: lighten($primary, 10%);

$primary-sport: rgba(0, 132, 255, 1);
$primary-sport-lighter: rgba(0, 171, 255, 1);
$primary-travel: rgba(192, 57, 43, 1);
$primary-travel-lighter: rgba(192, 57, 43, .7);
$primary-workshop: rgba(241, 196, 15, 1);
$primary-workshop-lighter: rgba(241, 196, 15, .7);

$background-dark: rgba(66, 72, 79, 1);

$menu-links: rgba(205, 213, 216, 1);
$menu-links-transparent: rgba(170, 178, 181, 1);
$active-text: rgba(40, 40, 40, 1);
$active-text-hovered: rgba(40, 40, 40, .7);
$inactive-text: rgba(170, 170, 170, 1);
$inactive-text-hover: darken($inactive-text, 10%);

$border: rgba(217, 226, 230, 1);

$selected-item: rgba(247, 248, 249, 1);

// for backend (/crew/*)
$subdata: rgba(195, 195, 195, 1);