.content{ transition: all .8s ease; }
.spacer-50{ display: block; padding: 25px 0; }
.spacer-100{ display: block; padding: 50px 0; }
.spacer-150{ display: block; padding: 75px 0; }
.spacer-200{ display: block; padding: 100px 0; }

.back-link{
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.8px;

    &:hover{ text-decoration: none; }
    &:visited{ text-decoration: none; }
    &:active{ text-decoration: none; }

    img, i{ margin-right: 10px; }
}

.link-with-arrow{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: $subprimary;
    text-decoration: none;

    &:hover{ text-decoration: none; color: $subprimary; }
    &:visited{ text-decoration: none; color: $subprimary; }
    &:active{ text-decoration: none; color: $subprimary; }

    img{
        margin-left: 10px;
        transition: all .4s ease;
    }
}
.link-with-arrow:hover > img{ margin-left: 20px; }

.link-primary,
.link-transparent-white{
    display: inline-block;
    padding: 20px 35px;
    border: 2px solid #fff;

    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;

    transition: all .4s ease;

    &:visited{
        color: #fff;
        text-decoration: none;
    }

    &:hover,
    &:active{
        text-decoration: none;
        background-color: #fff;
        color: #000;
    }
}

.link-primary{
    color: #fff;
    border-color: $subprimary;
    background-color: $subprimary;

    &:hover,
    &:visited,
    &:active{
        color: #fff;
        border-color: $primary;
        background-color: $primary;
    }
}

.btn-white, .btn-transparent{
    padding: 30px 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .8px;
    text-transform: uppercase;
    text-decoration: none;

    transition: all .4s ease;

    i{ margin-left: 10px; }
}

.btn-white{
    border: 2px solid #fff;
    color: $active-text;
    background-color: #fff;

    &:hover,
    &:visited,
    &:active{
        background-color: rgba(255, 255, 255, .7);
        color: $active-text;
        text-decoration: none;
    }
}

.btn-transparent{
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;

    &:visited{
        color: #fff;
        text-decoration: none !important;
    }

    &:hover,
    &:active{
        background-color: #fff;
        color: #000 !important;
        text-decoration: none !important;
    }

}


//section{ margin: 40px auto;}
blockquote{ margin: 20px 0 40px 0; p{ font-size: 18px; } }
.no-margin{ margin: 0; }
.no-padding{ padding: 0; }
.container-full{ padding: 0; }
.sticky{ position: fixed !important; }
.pos-rel{ position: relative !important; }

.container-triangles{
    .triangles-top-left{
        position: absolute;
        top: 90px; left: 0;
        width: 40%;
        height: 350px;
        @media screen and (max-width: 992px){ display: none; }
    }
    .triangles-top-right{
        position: absolute;
        top: 90px; right: 0;
        width: 40%;
        height: 350px;
        @media screen and (max-width: 992px){ display: none; }
    }
}

.container-triangles-sport{
    .triangles-top-left{ background: url('/images/sport/static-top-left.svg') no-repeat top left/contain; }
    .triangles-top-right{ background: url('/images/sport/static-top-right.svg') no-repeat top right/contain; }
}

.container-triangles-travel{
    .triangles-top-left{ background: url('/images/travel/static-top-left.svg') no-repeat top left/contain; }
    .triangles-top-right{ background: url('/images/travel/static-top-right.svg') no-repeat top right/contain; }
}

.container-triangles-workshop{
    .triangles-top-left{ background: url('/images/workshop/static-top-left.svg') no-repeat top left/contain; }
    .triangles-top-right{ background: url('/images/workshop/static-top-right.svg') no-repeat top right/contain; }
}

.container-triangles-head{
    section{
        margin-top: 120px;
        padding: 100px 0 0 0;
        text-align: center;

        @media screen and (max-width: 992px){
            text-align: left;
            padding: 60px 20px 0 20px;
        }
    }

    h1{ font-size: 90px; line-height: 75px; }
}

#triangle-topleft {
    width: 0; height: 0;
    position: absolute;
    left: 0; top: 100px;
    border-top: 250px solid rgba(0, 171, 255, 1);
    border-right: 250px solid transparent;
}

#triangle-left {
    width: 0; height: 0;
    position: absolute;
    left: 100px; top: 100px;
    border-left: 200px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 250px solid transparent;
    border-top: 100px solid rgba(0, 132, 255, 1);
}

#triangle-topright {
    width: 0; height: 0;
    position: absolute;
    right: 0; top: 100px;
    border-top: 200px solid rgba(255, 171, 0, 1);
    border-left: 350px solid transparent;
}

#triangle-right {
    width: 0; height: 0;
    position: absolute;
    right: 0; top: 100px;
    border-top: 225px solid transparent;
    border-right: 85px solid rgba(0, 171, 255, .7);
    border-bottom: 85px solid transparent;
}
#triangle-cta-top{
    width: 0; height: 0;
    position: absolute;
    left: 0; top: 0;
    border-top: 610px solid rgba(0, 132, 255, 1);
    border-right: 550px solid transparent;
}

#triangle-cta-bottom{
    width: 0; height: 0;
    position: absolute;
    left: 0; top: 0;
    border-top: 610px solid transparent;
    border-left: 550px solid rgba(0, 171, 255, .7);
}