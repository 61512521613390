// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// IMPORTS
@import 'variables';
@import 'helpers';
//@import 'textstyles';
@import 'flash';
@import 'sortable';
@import 'form';

// --- --- ---
// /crew/faq
.table-sort{
    .sort{ width: 40px; }
    .actions{ width: 200px; }
}

#crew-categories{
    #sortable-categories{
        margin: 40px auto;

        //.panel-category,
        //.panel-category-dummy{
        //    min-height: 100px;
        //
        //    .panel-heading{
        //        p{
        //            .current-order{
        //                color: $subdata;
        //                float: right;
        //            }
        //        }
        //    }
        //}
        //.panel-category-dummy{
        //    border: 1px solid $active-text;
        //    background-color: $inactive-text;
        //}
    }

    .form-category-order{
        display: none;

        .alert{
            background-color: $warning;
            text-align: center;

            button{
                margin-top: 30px;
            }
        }
    }
}
