.help-block{
    display: block;
    background: lighten($error, 50%);
    width: 100%;
    padding: 10px;

    p{ color: $error; margin: 0; }
}

.form-wrapper{
    .input-group{
        display: block;
        width: 100%;
        margin-bottom: 20px;

        label{
            width: 100%;
        }

        input[type="text"],
        select,
        textarea{
            width: 100%;
        }

        input[type="radio"]{
            margin-left: 10px;
            &:first-of-type{
                margin-left: 0;
            }
        }

        textarea{
            min-height: 100px;
        }
    }
}
